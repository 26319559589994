@use "../../variables";

.btn-white {
  padding: 14px 11px;
  border: 2px solid #e9f5f6;
  border-radius: 8px;
  font-family: NanitoSansBold;
  color: variables.$white;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: variables.$bold-green;
    background-color: #e9f5f6;
  }
}
