@use '../../variables';

.main-carusel-card {
  width: 279px;
  height: 305px;
  padding: 28px;
  background: #ffffff;
  box-shadow: 0px 4px 13px 3px rgba(23, 36, 43, 0.08);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: variables.$bold-green;

    & path {
      fill: variables.$white;
    }

    .title {
      color: variables.$white;
    }

    .text {
      color: variables.$light-green;
    }

    .learn-more {
      display: flex;
    }
  }

  .plant {
    width: 36px;
    height: 32.7px;
    object-fit: cover;
    background-image: url("../../assets/plant.svg");
  }

  .title {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 18px;
  }

  .text {
    margin: 0;
  }

  .learn-more {
    display: none;
    flex-direction: row;
    margin-top: 28px;
    font-size: 18px;
    line-height: 18px;
    color: variables.$white;

    & > div {
      margin-right: 9px;
    }
  }

  .arrow-right-white {
    width: 18px;
    height: auto;
  }

  @media only screen and (max-width: 800px) {
    & {
      background-color: variables.$bold-green;
  
      & path {
        fill: variables.$white;
      }
  
      .title {
        color: variables.$white;
      }
  
      .text {
        color: variables.$light-green;
      }
  
      .learn-more {
        display: flex;
      }
    }
  }
}
