@use 'variables';

@font-face {
  font-family: NanitoSans;
  src: url(./assets/fonts/NanitoSans/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: NanitoSansBold;
  src: url(./assets/fonts/NanitoSans/NunitoSans-Bold.ttf);
}

@font-face {
  font-family: NanitoSansExtraBold;
  src: url(./assets/fonts/NanitoSans/NunitoSans-ExtraBold.ttf);
}

* {
  box-sizing: border-box;
  font-family: NanitoSans;
  font-style: normal;
  font-size: 18px;
}

body {
  margin: 0;
  padding: 0;
}

a {
  color: variables.$black;
  text-decoration: none;
}

.title-section {
  font-family: NanitoSansExtraBold;
  font-weight: 800;
  font-size: 56px;
  color: variables.$bold-green-text;
  line-height: 67px;

  @media only screen and (max-width: 800px) {
    & {
      font-size: 33px;
      line-height: 40px;
    }
  }
}

.text-section {
  margin-top: 36px;
  margin-bottom: 40px;
  font-family: NanitoSans;
  font-weight: 600;
  font-size: 18px;
  color: variables.$opacity-text;
  line-height: 30px;

  @media only screen and (max-width: 800px) {
    & {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.vertical-orange-line {
  width: 15px;
  height: 100%;
  margin-right: 33px;
  background-color: variables.$orange;
}
