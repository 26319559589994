@use '../../variables';

.carusel-main {
  width: 50%;
  padding-left: 15px;

  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
  }

  .scroll {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 400px;
    padding: 10px;
    overflow: hidden;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: variables.$white;
  }

  .green-arrow {
    width: 30px;
    height: auto;
  }

  .green-slash {
    width: 6px;
    height: 23px;
  }

  .dot-wrapper {
    display: none;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }

  .item {
    margin: 0 15px;
  }

  .mobile-controls {
    display: none;
    margin-top: 32px;
  }

  @media only screen and (max-width: 1250px) {
    & {
      width: 100%;
      margin-top: 48px;
      padding-left: 0;
    }

    .controls-desktop {
      display: none;
    }

    .controls {
        margin-bottom: 0;
    }

    .scroll {
      height: auto;
      padding-left: 0;
    }

    .mobile-controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .dot-wrapper {
      display: flex;
      flex-direction: row;
      margin-left: 16px;
    }

    .button-dots {
      padding-left: 0;
    }

    .button-mobile-last {
        padding-right: 0;
    }
  }
}
