@use "../../variables";

%orange-arrow-link {
  display: flex;
  flex-direction: row;

  & div {
    margin-right: 8px;
    font-weight: 700;
    line-height: 23px;
    color: variables.$orange;
  }
}

.main {
  .section-2 {
    margin-top: 445px;
  }

  .section-3 {
    margin-top: 52px;
    padding-left: 0;
  }

  .secton-4 {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 156px;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;

    .section-right-4 {
      width: 35%;
      padding-top: 256px;
      padding-left: 14px;
      padding-right: 16px;
    }

    .section-left-4 {
      width: 50%;
      margin-right: 74px;
      // object-fit: contain;
      & img {
        width: 100%;
        // max-width: 993px;
        // object-fit: contain;
        // width: 993px;
        // height: 1062px;
      }
    }
  }

  .section {
    position: relative;
    display: flex;
    flex-direction: row;

    .bubble-1 {
      position: absolute;
      top: -90px;
      width: 9%;
    }

    .section-left-1 {
      margin-left: 10%;
      padding-top: 234px;
    }

    .section-right-1 {
      width: 100%;
      margin-right: 5%;
    }

    .section-left-2 {
      width: 50%;
      margin-left: 10%;
      margin-right: 106px;

      & > img {
        width: 868px;
        height: 516px;
      }
    }

    .section-right-2 {
      width: 50%;
      padding-left: 14px;
      padding-right: 16px;
      margin-top: 85px;

      .title-small {
        font-size: 48px;
      }
    }

    .section-left-3 {
      margin-left: 10%;
      padding-top: 104px;

      .title-small {
        font-size: 48px;
      }
    }

    .section-4-text-wrapper {
      position: relative;
      .vertical-orange-line {
        position: absolute;
        height: 88px;
        left: -48px;
      }

      .title-small {
        font-size: 48px;
      }
    }

    .mobile-1 {
      width: 782px;
      height: 924px;
      margin-left: -40px;
    }

    .text-section-mobile {
      font-size: 20px;
    }

    .text-section-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 35px;
      margin-bottom: 50px;

      .inner-border {
        padding-left: 33px;
        border-left: 5px solid variables.$orange;
      }

      .outer-border {
        margin-left: -33px;
        padding-left: 33px;
        border-left: 5px solid variables.$orange;
      }
    }

    .get-app {
      @extend %orange-arrow-link;
      margin-left: 38px;
    }

    .learn-more-us {
      @extend %orange-arrow-link;
    }

    .stores-wrapper {
      display: flex;
      flex-direction: row;

      & img {
        width: 209px;
        height: 62px;
        margin-right: 32px;
      }

      @media only screen and (max-width: 800px) {
        & {
          justify-content: center;
        }

        & img {
          width: 90%;
          height: auto;
          margin-right: 0;
        }
      }
    }

    @media only screen and (max-width: 1625px) {
      & {
        .section-left-1 {
          // margin-left: 5%;
        }

        .bubble-1 {
          display: none;
        }

        .section-right-1 {
          display: flex;
          justify-content: center;
          margin-right: 0%;
        }

        .mobile-1 {
          width: 682px;
          height: 724px;
        }

        .section-left-2 {
          width: 100%;
          margin-top: 50px;
          margin-left: 0;
          margin-right: 0;
          // margin-left: 5%;
          // margin-right: 25px;

          & > img {
            width: 100%;
            object-fit: contain;
          }
        }

        .section-right-2 {
          width: 100%;
          margin-right: 0%;
        }
      }
    }

    @media only screen and (max-width: 1250px) {
      & {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: 14px;
        padding-right: 16px;
      }

      .section-left-1 {
        width: 100%;
        margin-left: 0;
        padding-top: 69px;
      }

      .section-left-2 {
        width: 100%;

        & img {
          width: 100%;
          height: auto;
        }
      }

      .section-right-2 {
        margin-top: 0;

        .title-section {
          font-size: 27px;
          line-height: 33px;
        }

        .text-section {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .section-left-3 {
        margin-left: 0;
        padding-top: 63px;
        padding-left: 14px;

        .title-section {
          font-size: 27px;
          line-height: 33px;
        }

        .text-section {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .mobile-1 {
        width: 100%;
        height: auto;
        // width: 450px;
        // height: 451px;
        margin-top: 60px;
        margin-left: 0;
      }

      .text-section-mobile {
        margin-top: 16px;
        font-size: 16px;
      }

      .text-section-wrapper {
        margin-top: 0;
        margin-bottom: 0;

        .inner-border {
          margin-top: 20px;
          margin-bottom: 24px;
          padding-right: 15px;
        }

        .outer-border {
          margin-left: 0;
          padding-left: 16px;
          border-left: 5px solid variables.$orange;
        }
      }

      .outer-border-text {
        margin-left: 21px;
      }

      .get-app {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 1625px) {
    .section-2 {
      margin-top: 345px;
    }

    .secton-4 {
      margin-bottom: 100px;
    }
  }

  @media only screen and (max-width: 1250px) {
    .section-1 {
      flex-direction: column;
      justify-content: center;

      .section-left-1 {
        padding-top: 63px;
      }
    }

    .section-2 {
      flex-direction: column-reverse;
      margin-top: 145px;
    }

    .secton-4 {
      flex-direction: column-reverse;
      margin-top: 100px;
      margin-bottom: 0;

      .section-right-4 {
        width: 90%;
        padding-top: 0;
        align-self: center;
      }

      .section-left-4 {
        width: 100%;
        & img {
          width: 100%;
        }

        object-fit: contain;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .secton-4 {
      flex-direction: column-reverse;
      padding: 0;

      .section-left-2 {
        margin: 0;
      }

      // .section-right-4 {
      //   padding-top: 130px;
      // }

      .title-section {
        font-size: 27px;
        line-height: 33px;
      }

      .text-section {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
