@use '../../variables';

.privacy {
  position: relative;

  .header-absolute-wrapper {
    position: absolute;
    width: 100%;
  }

  .section-head-wrapper {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 200px;
    background-image: url('../../assets/pp-header-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;

    .section-head {
      width: 80%;
      margin: 0 auto;
    }

    .title {
      font-weight: 800;
      font-size: 56px;
      line-height: 67px;
      color: variables.$white;
    }
  }

  .impresssum-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 121px;
    padding-bottom: 173px;

    & > div {
      width: 80%;
      margin: 0 auto;
    }

    .title {
      margin-bottom: 30px;
      font-family: NanitoSansExtraBold;
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      color: variables.$bold-green;
    }

    & span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: variables.$opacity-text;
    }

    & .bold {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: variables.$opacity-text-bold;
    }

    .impresssum-info-2 {
      display: flex;
      flex-direction: column;
      margin-top: 56px;
      margin-bottom: 64px;
    }
  }

  @media only screen and (max-width: 1250px) {
    .section-head-wrapper {
      width: 100%;
      padding-top: 189px;
      padding-bottom: 190px;
      background-position: top left;

      .section-head {
        width: 100%;
        padding-left: 17px;
      }

      .title {
        font-size: 33px;
      }
    }

    .impresssum-info {
      padding-top: 60px;
      padding-bottom: 60px;

      .title {
        font-size: 23px;
        line-height: 28px;
      }

      & > div {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
      }

      .impresssum-info-2 {
        margin-top: 60px;
        margin-bottom: 60px;
      }
    }
  }
}
