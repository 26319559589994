@use "../../variables";

.about {
  position: relative;

  .header-absolute-wrapper {
    position: absolute;
    width: 100%;
  }

  .section-head-wrapper {
    width: 100%;
    padding-top: 272px;
    padding-bottom: 320px;
    background-image: url("../../assets/about-header-background.png");
    background-repeat: no-repeat;
    background-size: cover;

    .section-head {
      width: 80%;
      margin: 0 auto;
    }

    .title {
      font-family: NanitoSansBold;
      font-weight: 800;
      font-size: 56px;
      line-height: 67px;
      color: variables.$white;
    }

    .text {
      margin-top: 12px;
      font-weight: 400;
      font-size: 21px;
      line-height: 26px;
      color: #fcf0e6;
    }
  }

  .section {
    display: flex;
    flex-direction: row;

    .section-left-1 {
      width: 50%;

      & > img {
        width: 100%;
      }
    }

    .create-meaningful-connections {
      // width: 852px;
      // height: 537px;
    }

    .create-meaningful-connections-mobile {
      display: none;
    }

    .section-right-1 {
      max-width: 35%;
      margin-top: 35px;
      margin-left: 152px;

      & img {
        width: 100%;
      }

      .title-small {
        font-size: 48px;
      }
    }

    .inner-border {
      padding-left: 33px;
      font-family: NanitoSans;
      border-left: 5px solid variables.$orange;
    }

    .section-left-2 {
      position: relative;
      // right: -150px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 40%;
      z-index: 100;

      & > img {
        width: 100%;
      }

      .title-section-left {
        font-family: NanitoSansExtraBold;
        font-size: 33px;
        font-weight: 800;
        line-height: 40px;
        color: variables.$bold-green-text;
      }
    }

    .section-right-2 {
      position: relative;
      margin-top: 85px;
      width: 60%;

      & img {
        width: 100%;
      }

      .screens-mobile {
        display: none;
      }

      .screens-desktop {
        display: block;
        z-index: -1;
      }

      .bubble-mobile {
        position: absolute;
        top: 0;
        display: none;

        z-index: -1;
      }

      .bubble-1 {
        right: 0;
        width: 232px;
        height: 297px;
      }

      .bubble-2 {
        width: 232px;
        height: 297px;
      }
    }
  }

  .section-1 {
    position: relative;
    width: 100%;
    margin-top: 191px;

    .bubble-3 {
      position: absolute;
      right: 0;
      width: 380px;
      height: 485px;
      z-index: -1;
    }
  }

  .stores-wrapper {
    margin-top: 38px;

    & img {
      width: 209px;
      height: 62px;
      margin-right: 32px;
    }

    .google-store {
      margin-right: 0;
    }
  }

  .section-idea-wrapper {
    width: 100%;

    .section-idea {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto;
      margin-top: 99px;
      padding-top: 104px;
      border-top: 1px solid #e5e7e7;

      .section-idea-item {
        display: flex;
        flex-direction: column;
        width: 25%;

        .title-wrapper {
          margin-bottom: 22px;
        }

        .number {
          font-family: NanitoSansExtraBold;
          font-weight: 800;
          font-size: 21px;
          line-height: 26px;
          color: variables.$orange;
        }

        .title {
          font-family: NanitoSansExtraBold;
          font-weight: 800;
          font-size: 21px;
          line-height: 26px;
          color: variables.$bold-green;
        }

        .text {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          color: variables.$opacity-text;
        }
      }
    }
  }

  @media only screen and (max-width: 1650px) {
    .section-idea-wrapper {
      .section-idea {
        // width: 90%;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        margin: 0 auto;

        .section-idea-item {
          width: 25%;
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .section-head-wrapper {
      .section-head {
        // width: 90%;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        margin: 0 auto;
      }

      .title {
        font-size: 33px;
        line-height: 40px;
      }

      .text {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .section-1 {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 45px;

      .bubble-3 {
        display: none;
      }
    }

    .section-2 {
      display: flex;
      flex-direction: column;
    }

    .section {
      .section-left-1 {
        width: 100%;

        & > img {
          width: 100%;
        }
      }

      .create-meaningful-connections {
        display: none;
      }

      .create-meaningful-connections-mobile {
        display: block;
        margin-top: 107px;
        margin-bottom: 80px;
      }

      .section-right-1 {
        margin-top: 35px;
        margin-left: 0;
        max-width: 100%;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
      }

      .inner-border {
        padding-right: 15px;
      }

      .section-left-2 {
        right: 0;
        width: 100%;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;

        .title-section-left {
          margin-top: 123px;
          // text-align: center;
          font-size: 27px;
        }
      }

      .section-right-2 {
        margin-top: 85px;
        width: 100%;

        & img {
          width: 100%;
        }

        .screens-mobile {
          display: block;
          margin-bottom: 45px;
        }

        .screens-desktop {
          display: none;
        }

        .bubble-mobile {
          display: block;
        }
      }
    }

    .section-idea-wrapper {
      .section-idea {
        flex-direction: column;
        // width: 90%;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;

        .section-idea-item {
          width: 100%;
          margin-bottom: 35px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  @media only screen and (max-width: 550px) {
    .stores-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & img {
        width: 209px;
        height: 62px;
        margin-right: 0;
        margin-bottom: 32px;
      }

      .google-store {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .section-head-wrapper {
      width: 100%;
      padding-top: 166px;
      padding-bottom: 136px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../../assets/about-header-background-mobile.svg");
    }
  }
}
