@use "../../variables";

.footer {
  width: 100%;
  background-color: variables.$bold-green;
  color: variables.$light-green;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 54px;

    .logo {
      width: 130px;
      height: 39px;
    }

    .media-group {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;

      & img {
        width: 36px;
        height: 36px;
        margin-right: 16px;
      }
    }

    .common-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        width: 50%;
      }

      .common-left {
        .text {
          width: 381px;
          margin-top: 21px;
          margin-bottom: 35px;
        }
      }

      .common-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 44px;

        & a {
          margin-right: 60px;
          font-weight: bold;
          color: variables.$white;
        }
      }
    }
  }

  .terms-cpyright-wrapper {
    .terms-cpyright-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 18px;
      border-top: 1px solid #439a9f;

      .terms-cpyright {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin: 0 auto;

        & a {
          color: variables.$light-green;
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .content {
      width: 100%;
      padding-top: 46px;
      padding-left: 14px;

      .logo {
        width: 120px;
        height: 39px;
      }

      .common-wrapper {
        flex-direction: column-reverse;

        .common-left {
          width: 100%;
        }

        .common-right {
          width: 100%;
          height: 100%;
          margin-bottom: 24px;

          & > a {
            margin-bottom: 36px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .content {
      width: 100%;
      padding-top: 46px;
      padding-left: 14px;

      .logo {
        width: 120px;
        height: 39px;
      }

      .common-wrapper {
        flex-direction: column-reverse;

        .common-left {
          width: 100%;

          .text {
            width: 91%;
            margin-top: 22px;
            margin-bottom: 16px;
            font-size: 14px;
          }
        }

        .common-right {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          margin-bottom: 24px;

          & > a {
            margin-bottom: 36px;
          }
        }
      }
    }

    .terms-cpyright-wrapper {
      padding-left: 16px;
      padding-right: 16px;
      .terms-cpyright-content {
        padding-top: 28px;
        padding-bottom: 0;

        .terms-cpyright {
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          & > div {
            margin-bottom: 24px;
            font-size: 14px;

            & > a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
