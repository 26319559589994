$bold-green-text: #306e72;
$opacity-text: #95a0a0;
$opacity-text-bold: #818e8e;
$orange: #e78733;
$black: #000000;
$white: #ffffff;
$bold-green: #377e82;
$light-green: #c6e5e7;

:export {
  boldgreentext: $bold-green-text;
  opacitytext: opacity-text;
  orange: $orange;
  black: $black;
  white: $white;
  boldgreen: $bold-green;
  lightgreen: light-green;
}
