@use "../../variables";

.header {
  position: relative;
  width: 100%;
  font-weight: 700;

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    & > div {
      line-height: 23px;
      & > a {
        font-weight: 700;
        color: variables.$bold-green-text;
      }
    }

    & > img {
      width: 133px;
      height: 44px;
    }

    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .container-bar {
      display: none;
    }

    .bar1,
    .bar2,
    .bar3 {
      width: 24px;
      height: 3px;
      margin: 4px 0;
      border-radius: 16px;
      background-color: #a9d8db;
      transition: 0.4s;
    }

    .change .bar1 {
      transform: translate(0, 7px) rotate(-45deg);
    }

    .change .bar2 {
      opacity: 0;
    }

    .change .bar3 {
      transform: translate(0, -7px) rotate(45deg);
    }

    .main-logo-wrapper {
      width: 133px;
      height: 44px;
      margin-right: 93px;
    }

    .main-logo {
      width: 100%;
      height: 100%;
    }

    .link {
      padding: 38px 28px;

      & > a {
        font-weight: 700;
        font-size: 18px;
        color: variables.$bold-green;
      }
    }

    @media only screen and (max-width: 800px) {
      & {
        width: 100%;
        padding-left: 14px;
        padding-right: 14px;
      }

      & > img {
        width: 85px;
        height: 28px;
      }

      .header-content {
        flex: 1;
        justify-content: flex-end;
      }

      .main-logo-wrapper {
        width: 85px;
        height: 28px;
        margin-top: 20px;
        margin-bottom: 16px;
      }

      .container-bar {
        display: inline-block;
      }

      .link {
        display: none;
      }

      .main-logo {
        margin-right: 0px;
      }
    }
  }
}

.modal {
  position: absolute;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: variables.$white;
  transition: 2s;
  z-index: 100;
  overflow: hidden;

  & > img {
    position: absolute;
    z-index: -1;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
    padding-top: 23px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .link {
    padding-top: 186px;
    margin-left: 18px;
    margin-bottom: 105px;
    line-height: 34px;

    & > a {
      font-family: NanitoSansBold;
      font-weight: 700;
      font-size: 28px;
      color: variables.$bold-green;
    }
  }

  .media-mininazed-wrapper {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;

    .media-mininazed {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 24px;
      padding-bottom: 24px;
      border-radius: 8px;
      background-color: #e9f5f6;
    }

    .media-mininazed-title {
      margin-bottom: 16px;
      font-family: NanitoSansBold;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: variables.$bold-green;
    }

    .media-mininazed-media {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 185px;
    }

    .shop-minimized {
      width: 80px;
      height: 50px;
    }
  }

  .bubble-1 {
    position: absolute;
    left: -200px;
    width: 650.53px;
    height: 642.04px;
  }

  .modal-wrapper {
    display: none;
  }

  @media only screen and (max-width: 800px) {
    & {
      display: block;
    }

    & > img {
      width: 100%;
    }
  }
}
